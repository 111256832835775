import * as Wildfire from "../../layers/static/Wildfire";

import base, { Config, StaticMapLayerOrFolder } from "../../config";
import { camerasPima, camerasPinal } from "../../layers/misc/cameras";

import FEMA_NFHL from "../../layers/dynamic/FEMA_NFHL";
import { LatLng } from "leaflet";
import notEmpty from "../../shared/notEmpty";

const staticLayers: StaticMapLayerOrFolder[] = [
  Wildfire.last(3),
  {
    title: "Watershed Boundaries",
    layers: [
      {
        source: process.env.PUBLIC_URL + "/kml/HUC6.kml",
        name: "HUC6 Watershed",
      },
      {
        source: process.env.PUBLIC_URL + "/kml/HUC8.kml",
        name: "HUC8 Watershed",
      },
    ],
  },
].filter(notEmpty);

const config: Config = {
  ...base,
  ...{
    title: "Pinal County ALERT",
    center: new LatLng(33, -111),
    zoom: 9,
    basemap: "ImageryLabels",
    agencyPassList: [
      "Pinal County Flood Control District",
      "United States Geological Survey",
      "Flood Control District of Maricopa County",
    ],
    layers: {
      static: staticLayers,
      dynamic: [FEMA_NFHL],
      misc: [
        {
          title: "Webcams",
          layers: [
            { name: "Pinal Webcam", cameras: camerasPinal },
            { name: "Pima County Cameras", cameras: camerasPima },
          ],
        },
      ],
      lightning: false,
      cbrfc: false,
    },
  },
};

export default config;
